<template>
  <v-img
    class="align-center"
    height="200px"
    position="top"
    :src="require('@/assets/carousel.jpeg')"
  >
    <v-row justify="center">
      <v-col class="text-center" cols="12">
        <h1>{{ PageTitle }}</h1>
        <h4>{{ "Welcome to BEAM Lab@Fudan University" }}</h4>
      </v-col>
    </v-row>
  </v-img>
</template>

<script>
export default {
  name: "PageTopImgComponent",
  props: {
    PageTitle: String,
  },
};
</script>