<template>
  <v-app>
    <snackbar-component />
    <top-bar-component />
    <v-main>
      <page-top-img-component
        v-if="$route.name === 'publications'"
        :PageTitle="$route.meta.title"
      />
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
import SnackbarComponent from "./components/FrameComponents/SnackbarComponents/SnackbarComponent.vue";
import PageTopImgComponent from "./components/FrameComponents/PageTopImgComponent.vue";
import TopBarComponent from "./components/FrameComponents/TopBarComponents/TopBarComponent.vue";

export default {
  components: {
    TopBarComponent,
    SnackbarComponent,
    PageTopImgComponent,
  },
  name: "App",
};
</script>

<style scoped>
.v-application {
  font-family: "Times New Roman", Times, serif !important;
}
</style>