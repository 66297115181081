import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: "/",
    redirect: "/home"
  },
  {
    path: "/home",
    name: "home",
    component: () => import('../views/HomeView.vue'),
    meta: { title: 'Home' }
  },
  {
    path: '/publications',
    name: 'publications',
    component: () => import('../views/PublicationsView.vue'),
    meta: { title: 'Publications' }
  },
  {
    path: '/models/',
    redirect: "/models/BALMFold"
  },
  {
    path: '/models/:model_name',
    name: 'models',
    component: () => import('../views/ModelsView.vue'),
    meta: { title: 'Models' }
  },
  {
    path: '/about-us',
    name: 'aboutUs',
    component: () => import('../views/AboutUsView.vue'),
    meta: { title: 'About us' }
  },
]

const original = VueRouter.prototype.push

VueRouter.prototype.push = function push(location) {
  return original.call(this, location).catch(err => err)
}

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

// 跳转VueRouter时改变标签栏标题
router.beforeEach((to, from, next) => {
  document.title = "BEAM Lab | " + to.meta.title
  next()
})

export default router
