<template>
  <v-list tile flat dark color="#36579F" >
    <v-list-item-group>
      <v-list-item
        v-for="(model, index) in models"
        :key="index"
        :value="model"
        @click="jumpToModel(model)"
      >
        <v-list-item-title class="listItem">
          {{ model.model_name }}
        </v-list-item-title>
      </v-list-item>
    </v-list-item-group>
  </v-list>
</template>

<script>
export default {
  name: "ModelMenuComponent",
  data: () => ({
    models: [],
  }),
  methods: {
    modelsInit() {
      this.$axios.get("/models").then((resp) => {
        if (resp.data.resp_code == 200) {
          this.models = resp.data.resp_body;
        } else {
          this.$store.commit("setSnackbarMessage", {
            type: "error",
            message: resp.data.resp_body,
          });
        }
      });
    },
    jumpToModel(model) {
      this.$router.push({ path: "/models/" + model.model_name });
    },
  },
  created() {
    this.modelsInit();
  },
};
</script>

<style scoped>
</style>