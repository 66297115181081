<template>
  <div class="d-flex">
    <v-img :src="BeamLabLogo" class="mr-2" :width="(693 / 209) * 50" contain />
    <v-divider vertical dark />
    <v-img :src="FudanLogo" class="mx-2" :width="(233 / 72) * 40" contain />
    <v-divider vertical dark />
    <v-img :src="AILabLogo" class="ml-2" :width="(1005 / 123) * 35" contain />
  </div>
</template>

<script>
export default {
  name: "LogoComponent",
  data: () => ({
    BeamLabLogo: require("@/assets/beam_lab_blue.png"),
    FudanLogo: require("@/assets/school_logo.png"),
    AILabLogo: require("@/assets/shanghai_ai_lab.png"),
  }),
};
</script>

<style>
</style>