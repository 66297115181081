<template>
  <v-app-bar app height="80" color="#2d4e9a">
    <logo-component />
    <router-link-component />
  </v-app-bar>
</template>

<script>
import LogoComponent from "./LogoComponent.vue";
import RouterLinkComponent from "./RouterLinkComponent.vue";
export default {
  components: { RouterLinkComponent, LogoComponent },
  name: "TopBarComponent",
};
</script>