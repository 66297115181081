<template>
  <v-snackbar
    :color="snackbarType"
    v-model="showSnackbarFlag"
    centered
    top
    app
    timeout="2000"
  >
    {{ snackbarMsg }}
  </v-snackbar>
</template>

<script>
export default {
  name: "SnackbarComponent",
  data: () => ({
    showSnackbarFlag: false,
    snackbarMsg: "",
    snackbarType: "",
  }),
  watch: {
    "$store.state.snackbarMessage"() {
      this.snackbarMsg = this.$store.state.snackbarMessage.message;
      this.snackbarType = this.$store.state.snackbarMessage.type;
      this.showSnackbarFlag = true;
    },
  },
};
</script>

<style>
</style>