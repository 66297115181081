import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    snackbarMessage: sessionStorage.getItem('snackbarMessage'),
  },
  getters: {
  },
  mutations: {
    // 存入snackbarMessage的消息为一个二元对象 {type: "", message: ""}
    // 其中type表示消息类型，决定了弹出框的颜色
    // 其中message表示消息内容，决定了弹出框中呈现的语句
    // 在程序中调用用时直接使用js语句 this.$store.commit("setSnackbarMessage", {type: "", message: ""}); 进行调用
    setSnackbarMessage(state, snackbarMessage) {
      state.snackbarMessage = snackbarMessage
      sessionStorage.setItem('snackbarMessage', snackbarMessage)
    },
  },
  actions: {
  },
  modules: {
  }
})
