<template>
  <v-tabs dark right background-color="#2D4E9A" v-model="tabIndex">
    <v-tab text to="/home">
      {{ "Home" }}
    </v-tab>

    <v-menu offset-y open-on-hover>
      <template v-slot:activator="{ on, attrs }">
        <v-tab v-on="on" v-bind="attrs" text to="/models">
          {{ "Models" }}
          <v-icon size="20">
            {{ "mdi-menu-down" }}
          </v-icon>
        </v-tab>
      </template>
      <model-menu-component />
    </v-menu>

    <v-tab text to="/publications">
      {{ "Publications" }}
    </v-tab>
    <v-tab text to="/about-us">
      {{ "About Us" }}
    </v-tab>
  </v-tabs>
</template>

<script>
import ModelMenuComponent from "./ModelMenuComponent.vue";
export default {
  components: { ModelMenuComponent },
  name: "RouterLinkComponent",
  data: () => ({
    tabIndex: 0,
  }),
};
</script>

<style scoped>
.v-tab {
  text-transform: none !important;
  font-weight: 400;
  font-size: 20px;
}
</style>